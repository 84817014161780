import type { NewsletterSubscribeRequest } from "~~/types/request";

export const useNewsletter = (): NewsletterSubscribeRequest =>
  reactive({
    online_newsletter: {
      email: "",
      first_name: "",
      last_name: "",
      salutation: "",
      title: "",
    },
    paper_newsletter: {
      city: "",
      street: "",
      house_number: "",
      postal_code: "",
      first_name: "",
      last_name: "",
      salutation: "",
      title: "",
    },
    captcha: {
      captcha_input_code: "",
      captcha_token: "",
    },
    terms_confirmed: false,
  });

export const useOnlineNewsletter = ref(false);
export const usePaperNewsletter = ref(false);
